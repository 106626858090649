<template>
  <div class="special-offer px-18">
    <img :src="item.img"
     class="special-offer__img"
     :alt="item.title"
     width="218">
    <p class="special-offer__title text-above-md leading-h4 text-center text-white px-2" v-if="item.title">{{ item.title }}</p>
    <p class="special-offer__subtitle text-grey-10 text-h5 leading-loose text-center" v-if="item.subtitle">{{ item.subtitle }}</p>
  </div>
</template>

<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'

export default {
  name: 'SpecialOfferProduct',
  extends: Blok
}
</script>

<style lang="scss" scoped>
  .special-offer {
    &--first {
      @apply relative;
      &:after {
        @apply inline-block absolute text-white right-0 text-above-md leading-h4 top-1/2;
        content: '+';
        transform: translate(0, -50%);
      }
    }

    &__img {

    }

    &__head {

    }
    &__content {

    }
  }
</style>
